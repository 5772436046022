@charset 'UTF-8';
/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
►►► Style/pages/contact
━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */

#contact {
  width: 100%;
  height: auto;

  .page-informations,
  .page-coordinates,
  .page-form {
    padding: 10rem 0;
    @include mq(xs) {
      padding: 5rem 0;
    }
  }

  .page-coordinates {

    .left,
    .right {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
    }

    .left {
      #map-contact {
        width: 100%;
        height: 40rem;
      }
    }

    .right  {
      @include mq(md-low) {
        margin-top: 4rem;
      }

      h2 {
        font-size: 2.4rem;
        line-height: 1.0833;
      }

      ul {
        width: 100%;
        height: auto;
        display: table;

        li {
          width: 100%;
          &:not(:first-child) {
            margin-top: 3rem;
          }
          &.tel,
          &.email {
            width: 50%;
            display: inline-table;
          }

          > span.h6 {
            margin-bottom: $vr / 1.5;
            display: inline-block;
          }

          > span:nth-child(2) {
            display: block;
          }
        }

        li.social {

          a {

            &:not(:last-child) {
              margin-right: 0.5rem;
            }

            svg {
              padding-left: 0.5rem;
              height: 1.4rem;
              width: auto;

              path {
                fill: clr(primary);
              }
            }

            span {
              color: clr(primary);
            }
          }
        }
      }
    }
  }

  .page-informations {
    border-top: 1px solid clr(primary);
  }

  .page-form {
    border-top: 1px solid clr(primary);

    h2 {
      color: clr(primary);

      + form {
        margin-top: 6rem;
      }
    }
  }
}
