@charset 'UTF-8';
/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
►►► Style/abstracts/mixins
━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */


// ►►► mq
// ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
@mixin mq($media-query) {
  @if map-has-key($media-queries, $media-query) {
    @media #{map-get($media-queries, $media-query)} {
      @content;
    }
  }
  @else {
    @warn 'Media query is invalid: #{$media-query}.';
  }
}
