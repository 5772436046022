@charset 'UTF-8';
/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
►►► Style/pages/error
━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */

#error {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  &:after {
    content: "";
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: clr(primary);
    background-image: linear-gradient(to bottom, clr(primary), clr(secondary));
    opacity: 0.5;
  }

  .inner {
    position: relative;
    z-index: 2;
    max-width: 70rem;
    width: 100%;
    height: auto;
    background-color: clr(white);
    text-align: center;
    @include mq(md-up) {
      padding: 6rem;
    }
    @include mq(sm-low) {
      padding: 4rem 2rem;
    }
  }
}
