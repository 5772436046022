@charset 'UTF-8';
/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
►►► Style/components/forms
━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */

// Label
// ───────────────────────────────────
label {
  margin-bottom: 0.5rem;
	display: block;
  color: clr(primary);
  font-family: $ff-heading;
  font-size: 1.2rem;
  font-weight: 700;
  text-transform: uppercase;

  cursor: pointer;
}

.field-info {
	display: block;
	margin-top: 1rem;
	margin-bottom: 0;
	font-size: 1.2rem;
}

.field {
	margin-bottom: 3rem;
}

.form-group {
	display: flex;
	flex-wrap: wrap;
	width:100%;

	&.col-md-6 {
		width: calc(50% - 7px);
	}
	> .field {
		flex: 1;
		&:not(.one):nth-of-type(odd) {
			padding-right: 1.5rem;
		}
		&:nth-of-type(even) {
			padding-left: 1.5rem;
		}
	}

	.custom-checkbox {

		label	{
			color: clr(primary);
		}
	}
}
.form-row {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
	&.hide {
		position: absolute;
		opacity: 0;
		pointer-events: none;
	}
}
input, textarea, select, button {
	appearance: none;
}

input[type="submit"],
input[type="email"],
input[type="password"],
input[type="text"],
input[type="number"],
input[type="tel"],
input[type="date"],
textarea,
select {
	display: block;
	width: 100%;
	padding: 1rem 1.4rem;
	border-radius: 0;
	background-color: clr(white);
  border: 1px solid clr(grey);
	font-family: $ff-default;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.567;
	color: clr(primary);
	transition: border-color $animation;
	position: relative;
	&:hover, &:focus {
		border-color: clr(primary);
	}
}

textarea {
	resize: vertical;
	min-height: 15rem;
}

input[type="submit"] {
  padding: 1.4rem 2rem;
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1.833;
  text-transform: uppercase;
}

.field-icon {
	position: relative;
	input {
		padding-right: 6.6rem;
	}
	span {
		position: absolute;
		top: 0;
		right: 0;
		width: 4.6rem;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 0;
		border: 1px solid clr(primary);
		background-color: clr(white);
	}
}

// Input select
// ───────────────────────────────────
select {
	background-color: clr(white);
  background-image: svg-inline(arrow-down);
	background-repeat: no-repeat;
	background-size: 1.5rem;
  background-position: center right 2rem;
	padding: 1.1rem 4rem 1.1rem 2rem;
  cursor: pointer;

  &::-ms-expand {
    display: none;
  }

  &:disabled {
    cursor: default;
  }
}

// Input checkbox & input radio
// ───────────────────────────────────
.custom-checkbox,
.custom-radio {
	input[type="checkbox"],
	input[type="radio"] {
		position: absolute;
		height: 0;
    opacity: 0;

		+ label {
			display: block;
			position: relative;
			padding-left: 2.5rem;
      text-transform: none;
			font-size: 1.6rem;
			font-weight: 400;
			line-height: 1.4;
			transition: color $animation;
			user-select: none;
      cursor: pointer;

			&:before,
			&::after {
				content: "";
				position: absolute;
				top: 4px;
				left: 0;
				width: 1.4rem;
				height: 1.4rem;
      }

			&::before {
				border: 1px solid clr(primary);
      }

			&::after {
				width: 0.8rem;
				height: 0.8rem;
				top: 8px;
				left: 4px;
				background-color: clr(primary);
				opacity: 0;
				transition: opacity $animation;
			}
		}

		&:checked {
			+ label {
				&::after {
					opacity: 1;
				}
			}
		}
	}

	+ .error {
		padding-left: 4rem;
	}
}

// Input radio
// ───────────────────────────────────
.custom-radio {
	display: flex;
	align-items: center;
	input[type="radio"] {
		+ label {
			&:before,
			&::after {
				border-radius: 50%;
			}
		}
	}
}

.grecaptcha-badge  { visibility: hidden; }

.honeypot  {
  position: absolute;
  left: -9999px;
}

#grecaptcha  {
  margin-bottom: 2.5rem;
}

#form-essai {
	.h2 {
		color: clr(white);
		margin-bottom:3rem;
	}
	.custom-checkbox {
		width:100%;
		padding:1rem 0;
		a {
			color:clr(white)
		}
		input[type="checkbox"] {
			+ label {
				&::before {
					border: 1px solid clr(white);
				  }
				  &::after {
					background-color: clr(white);
				}
			}
		}
	}
}
div#recaptcha {
	position: absolute;
}
#form-quote {
	position: absolute;
    top: 0;
    left: 0;
    background:clr(primary);
	padding: 30px;
	display: none;


	label {
		color: clr(white);
	}

	.h2 {
		margin-bottom: 3rem !important;
	}

	a {
		color: clr(white);
	}
	.custom-checkbox {
		width:100%;
		padding:1rem 0;
		a {
			color:clr(white)
		}
		input[type="checkbox"] {
			+ label {
				&::before {
					border: 1px solid clr(white);
				  }
				  &::after {
					  background-color: clr(white);
				  }
			}
		}
	}
}
div#close-quote {
    position: absolute;
    top: 15px;
	right: 15px;
	padding: 10px;
	cursor: pointer;
}

.filters {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	> div {
		width: 11%;
		margin: 0.5rem;
		min-width: 100px;
		>div {
			width: 100%;
		}
		select {
			width: 100%;
		}
	} 
	.button {
		width: 15%;
		margin: 0.5rem;
		min-width: 100px;

	}
	button#clearFilters {
		display: none;
	}

}
#tradein_inject, .custom-estimation {
	.form-group {
		padding: .5rem;
		> div, > input{
			width: 100%;
		}
	}
	.cta-btn {
		padding: .5rem;
	> button {
		width: 100%;
	}
}
}
