@charset 'UTF-8';
/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
►►► Style/layout/header
━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */

header {
  @include mq(lg-up) {
    &.sticky .header-menu {
      position: fixed;
      z-index: 100;
      top: 0;
      left: 0;
      width: 100%;
      background-color: clr(white);
      border-bottom: 1px solid #CCC;
    }
  }

  .header-top {
    padding: 1.5rem 0;
    border-bottom: 1px solid #CCC;

    .logo {
      height: 8rem;
      @include mq(md-low) {
        height: 6rem;
      }

      a {
        display: block;
        width: 100%;
        height: 100%;

        img {
          height: 100%;
          width: auto;
        }
      }
    }

    .baseline {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      span {
        color: clr(primary);
        font-weight: 700;
        text-transform: uppercase;
      }
    }

    .buttons {
      height: 8rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;

      .button:nth-child(2) {
        margin-left: 2rem;
      }
    }
  }

  .header-bar {
    padding: 1rem 0;
    @include mq(lg-up) {
      display: none;
    }

    .buttons {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
  }

  .header-menu {
    @include mq(md-low) {
      .container > div > div {
        padding: 0;
      }
    }

    .menu {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      @include mq(md-low) {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        &.hidden {
          display: none;
        }
      }

      ul {
        margin: 0;
        padding: 0;
        list-style-type: none;

        li {
          margin: 0;
          padding: 0;
        }
      }

      .nav {
        align-self: stretch;
        display: flex;
        @include mq(lg-up) {
          flex-direction: row;
          align-items: flex-start;
          justify-content: center;
        }
        @include mq(md-low) {
          padding: 3rem;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          background-color: clr(primary);
        }

        > li {
          position: relative;
          margin: 0;
          padding: 0;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          @include mq(lg-up) {
            &:not(:first-child) {
              margin-left: 2.5rem;
            }
            &:hover {

              > a,
              > span {
                color: clr(primary);
                transition: color 400ms $animation-easing;
                &::before,
                &::before {
                  width: 100%;
                  transition: width 400ms $animation-easing;
                }

                svg {

                  path {
                    fill: clr(primary);
                    transition: fill 400ms $animation-easing;
                  }
                }
              }
            }
            &.current {

              > a,
              > span {
                color: clr(primary);
                &::before,
                &::before {
                  width: 100%;
                }

                svg {

                  path {
                    fill: clr(primary);
                  }
                }
              }
            }
            &.hide-for-lg {
              display: none;
            }
          }
          @include mq(md-low) {
            width: 100%;
            align-items: center;
            justify-content: flex-start;
            &:not(:first-child) {
              margin-top: 2rem;
            }
          }

          > a,
          > span {
            cursor: pointer;
            position: relative;
            display: inline-block;
            font-weight: 700;
            line-height: 1;
            text-decoration: none;
            text-transform: uppercase;
            transition: color 400ms $animation-easing;
            @include mq(lg-up) {
              padding: 2rem 0;
              font-size: 1.2rem;
              &::before,
              &::before {
                content: '';
                position: absolute;
                bottom: 1.5rem;
                left: 0;
                width: 0;
                height: 0.1rem;
                background-color: clr(primary);
                transition: width 400ms $animation-easing;
              }
            }
            @include mq(md-low) {
              padding: 1rem 0;
              width: 100%;
              color: clr(white);
              font-size: 1.4rem;
            }

            svg {
              position: relative;
              top: -0.1rem;
              left: 0.1rem;
              width: 1rem;
              height: auto;

              path {
                transition: fill 400ms $animation-easing;
                @include mq(lg-up) {
                  fill: clr(primary);
                }
                @include mq(md-low) {
                  fill: clr(white);
                }
              }
            }
          }

          &.submenu {

            ul {

              li {
                width: 100%;

                a {
                  position: relative;
                  display: inline-block;
                  color: clr(white);
                  font-weight: 700;
                  line-height: 1;
                  text-decoration: none;
                  text-transform: uppercase;
                  white-space: nowrap;
                }
              }
            }

            @include mq(lg-up) {

              ul {
                position: absolute;
                z-index: 20;
                top: 100%;
                margin: 0;
                padding: 3rem 4rem;
                background-color: clr(primary);
                border: 0.1rem solid rgba(clr(black), 0.1);
                opacity: 0;
                pointer-events: none;
                transition: opacity 300ms $animation-easing;

                li {
                  text-align: center;
                  &:not(:first-child) {
                    margin-top: 0.5rem;
                  }
                  &:hover,
                  &.current {

                    > a {
                      color: clr(dark);
                      &::before {
                        width: 100%;
                        transition: width 400ms $animation-easing;
                      }
                    }
                  }

                  > a {
                    color: clr(white);
                    font-size: 1.2rem;
                    text-decoration: none;
                    white-space: nowrap;
                    &::before {
                      content: '';
                      position: absolute;
                      bottom: -0.5rem;
                      left: 0;
                      width: 0%;
                      height: 0.1rem;
                      background-color: clr(white);
                      transition: width 400ms $animation-easing;
                    }
                  }
                }
              }

              &:hover {

                ul {
                  opacity: 1;
                  pointer-events: auto;
                  transition: opacity 300ms $animation-easing;
                }
              }
            }
            @include mq(md-low) {
              flex-direction: column;
              align-items: flex-start;
              justify-content: flex-start;
              &.active {

                ul {
                  display: block;
                }
              }

              ul {
                margin-top: 1rem;
                padding: 0 2rem;
                width: 100%;
                display: none;

                li {

                  a {
                    width: 100%;
                    color: clr(white);
                    font-size: 1.2rem;
                    white-space: unset;
                  }
                }
              }
            }
          }
        }
      }

      .social {
        align-self: stretch;
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: center;
        @include mq(md-low) {
          padding: 2rem 5rem;
          align-items: center;
          justify-content: flex-start;
          background-color: clr(secondary);
        }

        li {
          position: relative;
          margin: 0;
          padding: 0;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          &:not(:first-child) {
            margin-left: 1rem;
          }

          a {
            width: 2.5rem;
            height: 2.5rem;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            text-align: center;
            transition: background-color $animation;
            @include mq(lg-up) {
              background-color: clr(primary);

              svg path {
                fill: clr(white);
              }

              &:hover {
                background-color: clr(secondary);
              }
            }
            @include mq(md-low) {
              background-color: clr(white);

              svg path {
                fill: clr(secondary);
                transition: fill $animation;
              }

              &:hover {
                background-color: clr(secondary);

                svg path {
                  fill: clr(white);
                  transition: fill $animation;
                }
              }
            }

            &:hover {
              transition: background-color $animation;
            }

            svg {
              height: 1.2rem;
              width: auto;
            }
          }
        }
      }
    }
  }
}
