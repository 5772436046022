@charset 'UTF-8';
/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
►►► Style/globales/typography
━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */

// ►►► Headings
// ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  font-family: $ff-heading;
  margin: 0;
  text-transform: uppercase;
}

h1,
.h1 {
  color: clr(primary);
  font-size: 3rem;
  line-height: 1;
  font-weight: 700;
  @include mq(lg-up) {
    font-size: 4.6rem;
  }
  &:not(:last-child):not(.no-margin) {
    margin-bottom: $vr;
  }
}

h2,
.h2 {
  color: clr(primary);
  font-size: 2.8rem;
  font-weight: 700;
  line-height: 1.1111;
  @include mq(lg-up) {
    font-size: 3.6rem;
  }
  &:not(:last-child):not(.no-margin) {
    margin-bottom: $vr * 4/3;
  }
}

h3,
.h3 {
  color: clr(primary);
  font-size: 2.4rem;
  font-weight: 700;
  line-height: 1.08;
  &:not(:last-child):not(.no-margin) {
    margin-bottom: $vr * 4/3;
  }
}

h4,
.h4 {
  color: clr(primary);
  font-size: 2rem;
  font-weight: 470000;
  line-height: 1.08;
  &:not(:last-child):not(.no-margin) {
    margin-bottom: $vr * 1/2;
  }
}

h5,
.h5 {
  color: clr(primary);
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 1.08;
  text-transform: uppercase;
  &:not(:last-child):not(.no-margin) {
    margin-bottom: $vr * 1/2;
  }
}

h6,
.h6 {
  color: clr(primary);
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1.8333;
  text-transform: uppercase;
  &:not(:last-child):not(.no-margin) {
    margin-bottom: $vr / 2;
    border-bottom: 2px solid;
    padding-bottom: $vr / 2;
  }
}


// ►►► List
// ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
ol, ul {
  list-style-position: outside;
  padding-left: $vr * 2;
  &:not(:last-child):not(.no-margin) {
    margin-bottom: $vr * 1/2;
  }

  li {
    &:not(:last-child):not(.no-margin) {
      margin-bottom: $vr * 1/2;
    }
  }

  ul, ol {
    margin-top: 2rem;
    &:not(:last-child):not(.no-margin) {
      margin-bottom: $vr * 1/2;
    }
  }

  &.no-bullet {

    &, ul, ol {
      list-style: none;
      padding-left: 0;
    }
  }
}

// ►►► Text
// ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
p,
.text {
  max-width: 100%;
  font-family: $ff-default;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.5625;

  &.big {
    font-size: 2rem;
  }

  &.small {
    font-size: 1.2rem;
  }

  &:not(.no-margin):not(:last-child) {
    margin: 0 0 $vr 0;
  }
}

b,
strong,
.bold {
  font-weight: 700;
}


// ►►► Misc
// ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
figure {

  &:not(.no-margin):not(:last-child) {
    margin: 0 0 $vr 0;
  }
}

figcaption {
  margin: $vr / 2 0 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .credits {
    margin-left: auto;
  }
}

address {
  font-style: normal;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.5625;

  &:not(.no-margin):not(:last-child) {
    margin: 0 0 $vr 0;
  }
}

blockquote {
  position: relative;
  padding-left: $vr / 2;
  display: inline-block;
  max-width: 100%;
  margin: $vr 0 0 0;
  font-family: $ff-heading;
  font-size: 2.4rem;
  font-weight: 700;
  line-height: 1.1666;
  &:not(.no-margin):not(:last-child) {
    margin: $vr 0;
  }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 3px;
    height: 100%;
    background-color: clr(primary);
  }
}


// ►►► Links
// ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
a,
.link {
  position: relative;
  display: inline-block;
  text-decoration: underline;
  color: clr(primary);
  &:hover,
  &:focus,
  &.no-underline {
    text-decoration: none;
  }
}
