@charset 'UTF-8';
/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
►►► Style/pages/maintenance
━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */

#maintenance {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: clr(primary);

  .inner {
    max-width: 70rem;
    width: 100%;
    height: auto;
    background-color: clr(white);
    text-align: center;
    @include mq(md-up) {
      padding: 6rem;
    }
    @include mq(sm-low) {
      padding: 4rem 2rem;
    }
  }
}
