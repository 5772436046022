@charset 'UTF-8';
/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
►►► Style/layout/blog
━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */

#blog,
#post {
  .page-posts {
    padding: 5rem 0;
    @include mq(md-up) {
      padding: 8rem 0;
    }

    @include mq(lg-up) {
      .post:nth-child(n+4) {
        margin-top: 4.5rem;
      }

      .post:nth-child(3n+1) {
        padding-right: 4rem;
      }

      .post:nth-child(3n+2) {
        padding: 0 2rem;
      }

      .post:nth-child(3n+3) {
        padding-left: 4rem;
      }
    }

    @include mq(md) {
      .post:nth-child(n+3) {
        margin-top: 4.5rem;
      }

      .post:nth-child(2n+1) {
        padding-right: 3rem;
      }

      .post:nth-child(2n+2) {
        padding-left: 3rem;
      }
    }

    @include mq(sm-low) {
      .post:not(:first-child) {
        margin-top: 3rem;
      }
    }

    .top,
    .bottom {
      text-align: center;
    }

    .top {
      margin-bottom: 6rem;
    }

    .bottom {
      margin-top: 6rem;
    }

    .post {
      width: 100%;

      a {
        width: 100%;
        display: block;

        .image {
          width: 100%;
          height: 44rem;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .details {
          margin-top: 3rem;
          text-align: center;

          .h6:first-child {
            margin-bottom: 0.5rem;
          }

          h3,
          .h3 {
            margin-bottom: 1rem;
            color: clr(primary);
            text-transform: uppercase;
          }

          p {
            margin-bottom: 1rem;
          }

          .h6:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .page-pagination {
    padding: 5rem 0;
    border-top: 1px solid clr(tertiary);
    @include mq(md-up) {
      padding: 8rem 0;
    }

    .inner {
      display: flex;
      align-items: center;
      justify-content: center;

      a,
      span {
        color: clr(primary);
        font-weight: 700;
        text-decoration: none;
        text-transform: uppercase;
        transition: color $animation;
        &:not(.disabled):hover,
        &.current {
          color: clr(tertiary);
        }
        &.disabled {
          opacity: 0.5;
          cursor: not-allowed;
        }
      }

      .prev,
      .next {
        font-size: 1.2rem;
        line-height: 1.8333;
      }

      .prev {
        margin-right: 10rem;
      }

      .next {
        margin-left: 10rem;
      }

      .range {

        a,
        span {
          margin: 0 0.5rem;
          padding: 0 0.5rem;
          font-size: 1.6rem;
          line-height: 1.5625;
        }
      }
    }
  }
}
