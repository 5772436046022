@charset 'UTF-8';
/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
►►► Style/abstracts/variables
━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */


// ►►► Colors
// ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
$colors: (
  primary: #0062b0,
  secondary: #343a40,
  tertiary: #ED6F2D,

  alert: #FF4949,
  success: #13CE66,

  white: #FFFFFF,
  black: #232323,

  grey-light: #CCCCCC,
  grey: #90A2B0,
  grey-dark: #343a40
);


// ►►► Fonts
// ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
// Helvetica Neue Regular
// ───────────────────────────────────────────────
@font-face {
  font-family: "Helvetica Neue";
  src: url("fonts/helvetica-neue/helvetica-neue-regular.woff2") format("woff2"),
       url("fonts/helvetica-neue/helvetica-neue-regular.woff") format("woff"),
       url("fonts/helvetica-neue/helvetica-neue-regular.ttf") format("truetype");
  font-style: normal;
  font-weight: 400;
}


// Helvetica Neue Bold
// ───────────────────────────────────────────────
@font-face {
  font-family: "Helvetica Neue";
  src: url("fonts/helvetica-neue/helvetica-neue-bold.woff2") format("woff2"),
       url("fonts/helvetica-neue/helvetica-neue-bold.woff") format("woff"),
       url("fonts/helvetica-neue/helvetica-neue-bold.ttf") format("truetype");
  font-style: normal;
  font-weight: 700;
}

@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@400;700&display=swap');

$ff-heading: 'Barlow Condensed', sans-serif;
$ff-default: "Helvetica Neue", sans-serif;


// ►►► Animations
// ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
$animation-duration: 250ms;
$animation-easing: cubic-bezier(0.55, 0, 0.1, 1) ;
$animation: $animation-duration $animation-easing;


// ►►► Vertical rythmn
// ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
$vr: 3rem;


// ►►► Breakpoints
// ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
$breakpoints: (
  hu-px: 1920px,
  xl-px: 1440px,
  lg-px: 1280px,
  md-px: 1024px,
  sm-px: 768px,
  xs-px: 576px
);


// ►►► Gridlex
// ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
$gridSize: 144rem;
$gl-colCount: 24;
$gl-colWidth: $gridSize / $gl-colCount;
$gl-gutter: 0;
$gl-mq-list: (
  lg: map-get($breakpoints, lg-px),
  md: map-get($breakpoints, md-px),
  sm: map-get($breakpoints, sm-px),
  xs: map-get($breakpoints, xs-px)
);


// ►►► Media queries
// ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
$media-queries: (
  huge: '(min-width: #{map-get($breakpoints, hu-px) + 1})',
  xl: '(min-width: #{map-get($breakpoints, lg-px) + 1}) and (max-width: #{map-get($breakpoints, xl-px)})',
  xl-up: '(min-width: #{map-get($breakpoints, lg-px) + 1})',
  xl-low: '(max-width: #{map-get($breakpoints, xl-px)})',

  lg: '(min-width: #{map-get($breakpoints, md-px) + 1}) and (max-width: #{map-get($breakpoints, lg-px)})',
  lg-up: '(min-width: #{map-get($breakpoints, md-px) + 1})',
  lg-low: '(max-width: #{map-get($breakpoints, lg-px)})',

  md: '(min-width: #{map-get($breakpoints, sm-px) + 1}) and (max-width: #{map-get($breakpoints, md-px)})',
  md-ls: '(min-width: #{map-get($breakpoints, sm-px) + 1}) and (max-width: #{map-get($breakpoints, md-px)}) and (orientation: landscape)',
  md-pr: '(min-width: #{map-get($breakpoints, sm-px) + 1}) and (max-width: #{map-get($breakpoints, md-px)}) and (orientation: portrait)',
  md-up: '(min-width: #{map-get($breakpoints, sm-px) + 1})',
  md-up-ls: '(min-width: #{map-get($breakpoints, sm-px) + 1}) and (orientation: landscape)',
  md-up-pr: '(min-width: #{map-get($breakpoints, sm-px) + 1}) and (orientation: portrait)',
  md-low: '(max-width: #{map-get($breakpoints, md-px)})',
  md-low-ls: '(max-width: #{map-get($breakpoints, md-px)}) and (orientation: landscape)',
  md-low-pr: '(max-width: #{map-get($breakpoints, md-px)}) and (orientation: portrait)',

  sm: '(min-width: #{map-get($breakpoints, xs-px) + 1}) and (max-width: #{map-get($breakpoints, sm-px)})',
  sm-ls: '(min-width: #{map-get($breakpoints, xs-px) + 1}) and (max-width: #{map-get($breakpoints, sm-px)}) and (orientation: landscape)',
  sm-pr: '(min-width: #{map-get($breakpoints, xs-px) + 1}) and (max-width: #{map-get($breakpoints, sm-px)}) and (orientation: portrait)',
  sm-up: '(min-width: #{map-get($breakpoints, xs-px) + 1})',
  sm-up-ls: '(min-width: #{map-get($breakpoints, xs-px) + 1}) and (orientation: landscape)',
  sm-up-pr: '(min-width: #{map-get($breakpoints, xs-px) + 1}) and (orientation: portrait)',
  sm-low: '(max-width: #{map-get($breakpoints, sm-px)})',
  sm-low-ls: '(max-width: #{map-get($breakpoints, sm-px)}) and (orientation: landscape)',
  sm-low-pr: '(max-width: #{map-get($breakpoints, sm-px)}) and (orientation: portrait)',

  xs: '(max-width: #{map-get($breakpoints, xs-px)})',
  xs-ls: '(max-width: #{map-get($breakpoints, xs-px)}) and (orientation: landscape)',
  xs-pr: '(max-width: #{map-get($breakpoints, xs-px)}) and (orientation: portrait)',
);
