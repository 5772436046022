@charset 'UTF-8';
/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
►►► Style/layout/page
━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */

.page-contents {
  //  Alignement text
  //  ───────────────────────────────────
  .justify {
    text-align: justify;
  }

  .left {
    text-align: left;
  }

  .center {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;

    ol,
    ul {
      padding-left: 0;
    }
  }

  @include mq(lg-up) {
    .right {
      display: flex;
      align-items: flex-end;
      flex-direction: column;
      text-align: right;

      ol,
      ul {
        padding-left: 0;
      }
    }
  }
}

//  Backgrounds
//  ───────────────────────────────────
.page-section {
  padding: 5rem 0;
  @include mq(md-up) {
    padding: 8rem 0;
  }

  &.color_01 {
    .p, p{
      color: clr(white);
    }
    .h1, h1{
      color: clr(white);
    }
    .h2, h2{
      color: clr(white);
    }
    .h3, h3{
      color: clr(white);
    }
    .h6, h6{
      color: clr(white);
    }
    .li, li{
      color: clr(white);
    }
    .button {
      color: clr(primary);
      background-color: white;
      &:hover,
      &:focus,
      &:active {
        background-color: clr(secondary);
      }
    }
  }

  &.color_02 {
    .p, p{
      color: clr(white);
    }
    .h1, h1{
      color: clr(white);
    }
    .h2, h2{
      color: clr(white);
    }
    .h3, h3{
      color: clr(white);
    }
    .h6, h6{
      color: clr(white);
    }
    .li, li{
      color: clr(white);
    }
    .button {
      color: clr(secondary);
      background-color: white;
      &:hover,
      &:focus,
      &:active {
        background-color: clr(primary);
      }
    }
  }

  &.gradient_01 {
    .p, p{
      color: clr(white);
    }
    .h1, h1{
      color: clr(white);
    }
    .h2, h2{
      color: clr(white);
    }
    .h3, h3{
      color: clr(secondary);
    }
    .h6, h6{
      color: clr(white);
    }
    .li, li{
      color: clr(white);
    }
    .button {
      color: clr(primary);
      background-color: white;
      &:hover,
      &:focus,
      &:active {
        background-color: clr(secondary);
      }
    }
  }

  &.gradient_02 {
    .p, p{
      color: clr(white);
    }
    .h1, h1{
      color: clr(white);
    }
    .h2, h2{
      color: clr(white);
    }
    .h3, h3{
      color: white;
    }
    .h6, h6{
      color: clr(white);
    }
    .li, li{
      color: clr(white);
    }
    .button {
      color: clr(primary);
      background-color: white;
      &:hover,
      &:focus,
      &:active {
        background-color: clr(secondary);
      }
    }
  }

  &.white {
    .p, p{
      color: clr(black);
    }
    .h1, h1{
      color: clr(primary);
    }
    .h2, h2{
      color: clr(primary);
    }
    .h3, h3{
      color: clr(primary);
    }
    .h6, h6{
      color: clr(primary);
    }
    .li, li{
      color: clr(black);
    }
    .button {
      color: white;
      background-color: clr(primary);
      &:hover,
      &:focus,
      &:active {
        background-color: clr(secondary);
      }
    }
  }

  &.color_01,
  &.color_02,
  &.gradient_01,
  &.gradient_02 {
    
    .slider {

      .swiper-button-prev,
      .swiper-container-rtl .swiper-button-next {
        background-image: svg-inline(arrow-left-white);
      }

      .swiper-button-next,
      .swiper-container-rtl .swiper-button-prev {
        background-image: svg-inline(arrow-right-white);
      }
    }
  }

  &.color_01 {
    background-color: clr(primary);
  }

  &.color_02 {
    background-color: clr(secondary);
  }

  &.gradient_01 {
    background-image: linear-gradient(to bottom, clr(primary), clr(secondary));
  }

  &.gradient_02 {
    background-image: linear-gradient(to bottom, clr(secondary), clr(primary));
  }

  &.white {
    background-color: clr(white);

    + .white {
      border-top: 1px solid #CCC;
    }
  }

  hr {
    border-color: clr(secondary);
  }

  blockquote::before {
    background-color: clr(secondary);
  }

  //  Builders
  //  ───────────────────────────────────

  // Builder - cards
  &.builder-cards {

    .container:nth-child(2) {
      margin-top: $vr * 2;

      @include mq(lg-up) {
        > div > div:nth-child(n+6) {
          margin-top: $vr;
        }
      }
      @include mq(md-low) {
        > div > div:nth-child(n+2) {
          margin-top: $vr;
        }
      }
    }
  }

  // Builder - complex
  &.builder-complex {

    @include mq(lg-up) {
      > div > div > div {
        display: flex;
        align-items: center;
      }
    }
  }

  // Builder - file
  &.builder-file {

    .file {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      p + .button {
        margin-top: $vr;
      }
    }
  }

  // Builder - image
  &.builder-image {
    padding: 0;

    figure {

      a,
      img {
        width: 100%;
      }
    }
  }

  // Builder - pages
  &.builder-pages {
    @include mq(lg-up) {
      .is-2 {

        > div:nth-child(n+3) {
          margin-top: $vr;
        }

        .image {
          height: 50rem;
        }
      }

      .is-3 {

        > div:nth-child(n+4) {
          margin-top: $vr;
        }

        .image {
          height: 40rem;
        }
      }

      .is-4 {

        > div:nth-child(n+5) {
          margin-top: $vr;
        }

        .image {
          height: 30rem;
        }
      }
    }
    @include mq(md) {
      > div {

        > div:nth-child(n+3) {
          margin-top: $vr;
        }
      }
    }
    @include mq(sm-low) {
      > div {

        > div:nth-child(n+1) {
          margin-top: $vr;
        }
      }
    }

    .builder-pages--list,
    .builder-pages--cta {
      margin-top: $vr * 2;
    }

    .page {
      padding: 0 $vr;

      a {
        display: block;
      }
    }

    .image {
      @include mq(md-low) {
        height: 30rem;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .image + .details {
      margin-top: 2rem;
    }

    .dp-block:hover {
      color: clr(tertiary);
    }

    &.color_01,
    &.color_02,
    &.gradient_01,
    &.gradient_02 {

      .image {
        background-color: clr(white);
      }

      h3 {
        color: clr(white);
      }
    }

    &.white {

      .image {
        background-color: clr(primary);
      }

      h3 {
        color: clr(primary);
      }
    }
  }
}

#details_inject {
  h3 {
    margin:0;
    color:clr(primary)
  }
  .accordion--item {
    ul {
      columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
    }
  }
  .sticky-infos {
    position:sticky;
    top:15px;
    padding: 30px;
    background: clr(primary);
    color: clr(white);
    z-index: 1;
    h1 {
      color: clr(white);
    }
    .h2 {
      color: clr(white);
      margin:0
    }
    .h5 {
      color: clr(white);
      max-width: 130px;
    }
    p {
      color: clr(white);
      margin:0 0 1rem;
      font-size: 1.6rem;
    }
    span.small-infos {
      font-size: 1.2rem;
  }
  
  .content-prix {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom:4rem
  }

  .container-flags {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin:2rem 0;
    flex-wrap: wrap;
    margin: 3rem 0 2rem;
    span.flags {
      display: flex;
      align-items: center;
      width: auto;
      justify-content: center;
      text-transform: uppercase;
      font-size: 1.2rem;
      font-weight: 700;
      border-radius: 20px;
      line-height: 1;
      margin-bottom: 1rem;
      .icons {
        background: #FFF;
        height: 40px;
        width: 40px;
        border-radius: 20px;
        object-fit: initial;
        padding: 5px;
        margin-right: 10px;
      }
    }
  }
  .button.secondary {
    background: clr(black);
    color:clr(white);
    width:100%;
  }
}
div#imageGallery {
  margin-top:-8rem;
  position: relative;
  figure {
    padding:30px 15px;
  }
}
}

.card-deck {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-bottom:2rem;
  flex-wrap: wrap;
.card {
  padding: 0 15px;
  width:33.333%;
  margin-bottom:2rem;
  min-width: 320px;
  .card-body {
    .h2 {
      margin:0;
    }
    .h3 {
      color:clr(primary);
      margin:0;
      padding-top: 1rem;
      display: block;
    }
    p {
      margin: 0;
      padding: 0 60px 0 0;
      font-size: 1.2rem;
      color:clr(primary);
      max-width: 80%;
    }
  }
  .button {
    &.tertiary {
      background-color: clr(white);
      color: clr(primary);
      &:hover,
      &:focus,
      &:active {
        color: clr(secondary);
      }
    }
  }
}

}

.slider-handle.round.hide {
  display: none;
}
div#financial-div {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
}
div#financing_inject {
  background: clr(primary);
  color: clr(white);
  width: calc(50% - 15px);
  padding: 30px;


label {
  color: clr(white);
  border-bottom: 2px solid;
  padding: 10px 0 10px;
  font-size: 1.8rem;
}

.slider.slider-horizontal {
  width: 100%;
  height: auto;
  margin-bottom: 2rem;
}

span.range {
  display: none;
}

.slider.slider-horizontal .slider-track {
  height: 10px;
  border-radius: 10px;
  background: clr(white);
  border: none;
  left: 30px;
  width: calc(100% - 30px);
}

.slider-handle.round {
  background: clr(black);
  border-radius: 0;
  width: 0px;
  margin: 0 !important;
  height: 0;
}

.slider-handle.round.hide {
  display: none;
}

div#tooltip {
  top: -1px !important;
  position: relative;
  left: auto !important;
  width: auto !important;
}

.slider-selection {
  background: clr(black);
}

.tooltip-inner {
  color: clr(white);
  font-weight: 700;
}

.h2, h2 {
  margin: 0 0 2rem;
  color: clr(white);
}
.h4 {
  color:clr(white);
}
p {
  color: clr(white);
  font-size: 1.2rem;
  margin: 2rem 0;
}

.button {
  width: 100%;
  background: clr(black);
}
}
ins {
  text-decoration: none;
}
#form-essai {
    background: clr(secondary);
    color: clr(white);
    width: calc(50% - 15px);
    padding: 30px;
    label {
      color: clr(white);
    }
    .button {
      width:100%;
    }
}
.container.page-header.split {
  max-width: unset;
}
.container.page-header.split > div {
  align-items: stretch;
}
div#search_inject {
  top: 0;
  position: sticky;
  z-index: 1;
  background: #FFF;
  padding: 15px 0;
  border-bottom: 1px solid #CCC;
  margin-bottom: 3rem;
  .button.tertiary {
    background-color: #fff;
    color: #0062b0;
    margin:0
}
}
.vehicleDataPagerCtn {
  .label, .firstPage, .lastPage {
    display: none;
  }
}
.card-body {
  position: relative;
  text-align: left;
  padding: 0 30px;
}

.prices {
  position: absolute;
  top: 0;
  right: 30px;
  text-align: right;
}

.card-deck .card .card-body .h3 {
  padding-bottom: 1rem;
}

.card-deck .card .button.tertiary {
  width: calc(100% - 60px);
  text-align: left;
  padding-left: 0;
}
span.car_price:not(.h3) {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1.4rem;
  top: -15px;
  position: relative;
}

#stock_inject {
  .js-slider {
    margin:0;
    img.card-img-top {
      width: 100%;
      max-width: unset;
      height: 100%;
      object-fit: cover;
  }
  .swiper-wrapper {
    margin: 0;
  }
  .card.swiper-slide {
    margin: 0;
    padding:0;
    a {
        display: block;
        height: 100vh;
    }
  }
  .card-body {

  &.ecran {
    position: absolute;
    bottom:0;
    left:10%;
    padding:30px 30px 60px;
    background: #FFF;
    width:650px;
  p {
    font-size: 2rem;
  }
    .prices {
      position: absolute;
      top: 38px;
      right: 30px;
      text-align: right;
  }
  .card-title {
    font-size: 5rem;
  }
  }
}
  .card-footer {
      display: none;
  }
}
}
@include mq(md-low) {
  #details_inject .sticky-infos{
    position: relative;
  }
  div#financing_inject {
    width: 100%;
  }
  div#financial-div {
    flex-wrap: wrap;
    justify-content: center;
}

div#form-essai {
    width: 100%;
}

.grid-center-noBottom.spacebetween {
    justify-content: center !important;
}
}