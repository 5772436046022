@charset 'UTF-8';
/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
►►► Style/globales/bases
━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */

* {
  margin: 0;
  padding: 0;
  outline: none;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

body {
  overflow-x: hidden;
  scroll-behavior: smooth;
  max-width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  color: clr(primary);
  font-family: $ff-default;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.5625;
}

hr {
  width: 100%;
  margin: $vr * 2 auto;
  border: none;
  border-bottom: 1px solid rgba(clr(black), 0.1);
}

* + hr {
  margin-top: 0;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
  transition: opacity $animation;
  &.lazyload{
    opacity: 0;
  }
  &.lazyloaded{
    opacity: 1;
  }
}

iframe,
video {
  max-width: 100%;
  width: 100%;
  min-height: 25rem;
  @include mq(lg-up) {
    min-height: 40rem;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p, li, a, span, blockquote,
label, input, textarea, button, select, address
dt, dd, th, td,
figcaption {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a,
button,
label,
input,
textarea,
select {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

button {
  -webkit-appearance: none;
  border: none;
  border-radius: 0;
  cursor: pointer;
}

::-moz-selection {
  background: rgba(clr(black),0.8);
  color: clr(white);
}

::selection {
  background: rgba(clr(black),0.8);
  color: clr(white);
}

[class*="grid-"] {
  margin: 0;
  align-items: flex-start;
}

[class*="col-"] {
  position: relative;
}

.container {
  margin: 0 auto;
  max-width: $gridSize;
  width: 100%;
}
.results-estimation {
  padding: 70px 0;
}

.d-block.w-100.sell-cars-results {margin-bottom: 60px;}