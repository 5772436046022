@charset 'UTF-8';
/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
►►► Style/components/modal
━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: center;
  pointer-events: none;
  opacity: 0;
  transition: opacity $animation;
  &.is-open {
    pointer-events: all;
    opacity: 1;
    .modal--inner,
    .close {
      pointer-events: all;
    }
  }

  .modal-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(clr(primary), 0.8);
  }

  .modal-wrapper {
    position: relative;
    max-width: 90vw;
    width: 100%;
    max-height: 90vh;
    margin: 0 auto;
    pointer-events: none;
    background-color: clr(white);

    @include mq(lg-up) {
      max-width: 60vw;
    }
  }

  .modal--inner {
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    padding: $vr * 3 $vr * 2;
    overflow: auto;
    z-index: 5;

    .modal-item {

      + .modal-item {
        margin-top: $vr / 2;
      }

      img {
        max-width: 20rem;
        max-height: 20rem;
      }

      .h3 {
        margin-bottom: $vr / 3;
        color: clr(primary);
        text-transform: uppercase;
      }
    }
  }

  .close {
    position: absolute;
    top: 2rem;
    right: 2rem;
    border: 0;
    background: none;
    font-family: $ff-default;
    font-size: 2.6rem;
    font-weight: 700;
    line-height: 1;
    text-transform: uppercase;
    cursor: pointer;
    z-index: 10;
    span {
      margin-left: $vr / 2;
    }
  }
}
