@charset 'UTF-8';
/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
►►► Style/elements/cookies
━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */


#cookies {
  position: fixed;
  z-index: 100;
  bottom: 0;
  left: 0;
  width: 100%;
  height: auto;
  display: none;
  background-color: clr(primary);
  &.active {
    display: block;
  }

  .inner {
    padding: 2rem;
    width: 100%;
    height: auto;
    display: flex;
    @include mq(lg-up) {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
    @include mq(md-low) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }

    p {
      margin-bottom: 0;
      color: clr(white);
      @include mq(md-low) {
        font-size: 1.2rem;
      }

      a {
        color: clr(white);
        text-decoration: underline;
      }
    }

    button {
      @include mq(lg-up) {
        margin-left: 5rem;
      }
      @include mq(md-low) {
        margin-top: 1rem;
        margin-left: auto;
      }
    }
  }
}
