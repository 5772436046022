@charset 'UTF-8';
/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
►►► Style/layout/hero
━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */

.page-header {
  padding: 11.5rem 0;

  &.big {
    padding: 17rem 0;
  }

  @include mq(sm-low) {
    &, .big {
      padding: 8rem 0;
    }
  }

  &.color_01,
  &.color_02,
  &.gradient_01,
  &.gradient_02,
  &.black {

    h1, p {
      color: clr(white);
    }
  }

  &.color_01 {
    &, .swiper-slide {
      background-color: clr(primary);
    }
  }

  &.color_02 {
    &, .swiper-slide {
      background-color: clr(secondary);
    }
  }

  &.gradient_01 {
    &, .swiper-slide {
      background-image: linear-gradient(to bottom, clr(primary), clr(secondary));
    }
  }

  &.gradient_02 {
    &, .swiper-slide {
      background-image: linear-gradient(to bottom, clr(secondary), clr(primary));
    }
  }

  &.white {
    &, .swiper-slide {
      background-color: clr(white);
      border-top: 1px solid clr(primary);
      border-bottom: 1px solid clr(primary);
    }
  }

  &.black {
    &, .swiper-slide {
      background-color: clr(black);
    }
  }

  &.with-image {
    position: relative;

    .parallax {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      min-height: 100%;
      opacity: 0.3;
      z-index: 1;

      .simpleParallax {
        width: 100%;
        height: 100%;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    .inner {
      position: relative;
      z-index: 2;
    }
  }

  &.split {
    padding: 0;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
    .inner {
      padding: 17rem 12rem 17rem 6rem;

      @include mq(sm-low) {
        padding: 8rem 2rem;
      }
    }
    &.right {
      .split-col-text {
        order: -1;
      }
    }
  }

  &.slider-image {
    position: relative;

    .swiper-container {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.3;
      z-index: 1;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }

    .inner {
      position: relative;
      z-index: 2;
    }
  }

  &.slider-content {
    padding: 0;
    .swiper-slide {
      height: auto !important;
    }
    .inner {
      padding: 17rem 0;

      @include mq(sm-low) {
        padding: 8rem 0;
      }
    }
  }
}
.split-col-text .inner.text-left {
  max-width: 720px;
}