@charset 'UTF-8';
/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
►►► Style/tools/revealer
━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */

[data-r] {
  opacity: 0;
  animation: none;

  &.revealed {
    opacity: 1;
  }
}

.r__loaded [data-r].r__default.revealed {
  animation: r__default 1s $animation-easing both;
}

.r__loaded [data-r].r__fromLeft.revealed {
  animation: r__fromLeft 1s $animation-easing both;
}

.r__loaded [data-r].r__fromRight.revealed {
  animation: r__fromRight 1s $animation-easing both;
}

@keyframes r__default {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes r__fromLeft {
  0% {
    opacity: 0;
    transform: translateX(-30px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

@keyframes r__fromRight {
  0% {
    opacity: 0;
    transform: translateX(30px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}
