@charset 'UTF-8';
/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
►►► Style/pages/home
━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */

.home-header {

  .h4,
  h1 {
    color: clr(white);
  }

  .button {
    background-color: clr(white);
    color: clr(black);
    &:hover {
      background-color: clr(black);
      color: clr(white);
    }
  }

  .parallax {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    min-height: 100%;

    .simpleParallax {
      width: 100%;
      height: 100%;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .swiper-home {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    .swiper-wrapper {
      width: 100%;
      height: 100%;

      .swiper-slide {
        background-size: cover;
        background-position: center;
      }
    }

    .swiper-prev,
    .swiper-next {
      position: absolute;
      z-index: 10;
      top: 50%;
      width: 3rem;
      height: 3rem;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      svg {
        width: 100%;
        height: 100%;

        path {
          fill: clr(black);
        }
      }
    }

    .swiper-prev {
      right: auto;
      left: 3rem;
    }
    .swiper-next {
      right: 3rem;
      left: auto;
    }
  }

  &.shared {

    .media {
      position: relative;
      width: 100%;
      height: 100%;
      @include mq(md-low) {
        height: 35rem;
      }

      .parallax,
      .swiper-container {
        @include mq(md-low) {
          position: relative;
        }
      }
    }

    .details {
      position: relative;
      z-index: 2;
      padding: 25rem 8rem;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      background-color: clr(black);
      @include mq(md) {
        padding: 12rem 6rem;
      }
      @include mq(sm-low) {
        padding: 8rem 3rem;
      }
      &.left {
        align-items: flex-start;
        text-align: left;
      }
      &.center {
        align-items: center;
        text-align: center;
      }
      &.right {
        align-items: flex-end;
        text-align: right;
      }

      .h4,
      h1 {
        max-width: 80%;
        @include mq(sm-low) {
          max-width: 100%;
        }
      }
    }
  }

  &.simple {

    .details {
      position: relative;
      z-index: 2;
      padding: 25rem calc((100% - #{$gridSize}) / 2);
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      &.color {
        background-color: rgba(clr(black), 0.7);
      }
      &.black {
        background-color: rgba(clr(black), 0.3);
      }
      &.left {
        align-items: flex-start;
        text-align: left;
      }
      &.center {
        align-items: center;
        text-align: center;
      }
      &.right {
        align-items: flex-end;
        text-align: right;
      }
      @include mq(md) {
        padding: 12rem 6rem;
      }
      @include mq(sm-low) {
        padding: 8rem 3rem;
      }

      .h4,
      h1 {
        max-width: 50%;
        @include mq(md) {
          max-width: 80%;
        }
        @include mq(sm-low) {
          max-width: 100%;
        }
      }
    }
  }
}
